<template>
<el-row align="center">
  <el-col :span="4">
    <h1>
      思流
      <img src="@assets/images/logo@2x.png" alt="思流" class="logo">
    </h1>
  </el-col>
  <el-col :span="16" class="current-time">
    <h5 class="time">{{time}}</h5>
    <p class="date">{{date}} {{weekday}}</p>
  </el-col>
  <el-col :span="4" class="user-info">
    <span class="notice">
      <i class="el-icon-bell"></i>
    </span>
    <el-avatar :size="32" :src="profile.avatar || avatar"></el-avatar>
    <el-dropdown>
      <span class="username">{{profile.username}}</span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item @click="logout">
            登出
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </el-col>
</el-row>
</template>

<script>
import {
  mapState
} from 'vuex'
import moment from 'moment'

const DefaultAvatar = require('../assets/images/avatar@2x.png');
export default {
  data() {
    return {
      avatar: DefaultAvatar,
      date: "",
      time: "",
      weekday: "",
    }
  },
  computed: mapState({
    profile: state => state.auth.profile,
    roles: state => state.auth.roles,
  }),
  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  },
  mounted() {
    let that = this
    moment.locale("zh-cn")
    this.timer = setInterval(
      function () {
        // var now = new Date()
        that.date = moment().format('YYYY-MM-DD')
        that.time = moment().format('HH:mm:ss')
        that.weekday = moment().format('dddd')
      }, 1000
    )
  },
  beforeUnmount() {
    if(this.timer) {
      clearInterval(this.timer)
    }
  },
}
</script>

<style lang="scss" scoped>
h1 {
  position: relative;
  width: 60px;

  .logo {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
  }

  color: transparent;
}

.current-time {
  text-align: center;
  @include color-primary();

  .time {
    margin-bottom: 8px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 2px;
    font-size: 24px;
  }

  .date {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    @include color-secondary();
  }
}

.user-info {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .notice {
    position: relative;
    padding: 5px;
    cursor: pointer;

    i {
      font-size: 18px;
      color: #A2A9B7;
    }

    &:after {
      content: '';
      position: absolute;
      top: 5px;
      right: 5px;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background-color: $color-danger;
    }
  }

  .el-avatar {
    margin-left: 40px;
  }

  .username {
    @include text-regular($color-text-primary);
    @include color-primary();
    margin-left: 8px;
    font-weight: bold;

    &:after {
      content: '';
      display: inline-block;
      margin-left: 8px;
      @include triangle-down(4px, #C7CBD3);
    }
  }
}
</style>
