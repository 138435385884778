import { departments as departmentsData } from '../modules/organizations/mockdata';

const state = () => ({
  departments: [],
  isFetchingDepartments: false,
});

const getters = {
  departments: (state) => ({
    departments: state.departments
  }),
}

const actions = {
  setDepartments({ commit }, departments) {
    commit('setDepartments', departments);
  },
  getDepartments({ commit }) {
    commit('setIsFetchingDepartments', true);
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('setDepartments', departmentsData);
        commit('setIsFetchingDepartments', false);
        resolve()
      }, 1000);
    })
  },
}

const mutations = {
  setDepartments(state, departments) {
    state.departments = departments
  },
  setIsFetchingDepartments(state, isFetching) {
    state.isFetchingDepartments = isFetching;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}