const state = () => ({
  authenticated: false,
  roles: [],
  profile: {  
    username: '',
    avatar: '',
  }
});

const getters = {
  roles: (state) => ({
    roles: state.roles
  }),
  profile: (state) => ({
    profile: state.profile
  }),
}

const actions = {
  login({ commit }, { username, avatar, roles }) {
    commit('setProfile', { username, avatar });
    commit('setRoles', roles);
    commit('setAuthenticated', true);
  },
  logout({ commit }) {
    commit('setProfile', { username: '', avatar: '' });
    commit('setRoles', []);
    commit('setAuthenticated', false);
  }
}

const mutations = {
  setProfile(state, { username, avatar }) {
    state.profile = {
      username, avatar,
    }
  },
  setRoles(state, roles) {
    state.roles = roles
  },
  setAuthenticated(state, authenticated) {
    state.authenticated = authenticated
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}