<template>
  <div class="card">
    <div v-if="titleVisible" class="card-header">
      <div class="title">
        <h4>{{title}}</h4>
        <h5 v-if="subTitle">{{subTitle}}</h5>
      </div>
      <el-link v-if="link" class="link" :href="link.href">{{link.title || '更多'}}</el-link>
    </div>
    <div class="card-content">
      <div class="loading" v-if="loading"><i class="el-icon-loading"></i></div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    titleVisible: {
      type: Boolean,
      default: true,
    },
    loading: Boolean,
    subTitle: String,
    link: Object,
  }
}
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  @include background-color-content();
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid;
    @include border-color-card();
    padding: 20px;
    &:before {
      content: '';
      display: block;
      width: 8px;
      height: 22px;
      background-color: $color-primary;
    }
    .title {
      display: flex;
      align-items: center;
      flex: 1;
      margin-left: 10px;
      h4 {
        @include title-primary($color-text-primary);
        @include color-primary();
      }
      h5 {
        margin-left: 10px;
        @include text-regular($color-text-secondary);
      }
    }
    .link {
      @include text-secondary($color-text-regular);
      &:hover {
        color: $color-primary;
      }
    }
  }
  .card-content {
    position: relative;
    flex: 1;
    padding: 20px;
  }
  .loading {
    @include position-center();
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 30px;
    color: $color-primary;
    background: $color-white;
  }
}
</style>