<template>
  <el-container class="container">
    <el-header :height="'64px'" v-if="!this.$route.meta.hideHeader">
      <Header />
    </el-header>
    <el-container class="content">
      <el-aside width="140px" v-if="!this.$route.meta.hideAside"><Menu /></el-aside>
      <el-main :style="this.$route.meta.hideAside && 'padding: 0'">
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { SESSION_STORAGE_KEYS } from "@constants/storage";
import Header from "./components/Header";
import Menu from "./components/Menu";

export default {
  name: "App",
  components: {
    Header,
    Menu,
  },
  mounted() {
    const tokenName = sessionStorage.getItem(SESSION_STORAGE_KEYS.tokenName);
    const token = sessionStorage.getItem(SESSION_STORAGE_KEYS.token);
    if (tokenName && token) {
      const profile = sessionStorage.getItem(SESSION_STORAGE_KEYS.profile) || {};
      this.$store.dispatch("auth/login", {
        username: profile.username,
        avatar: profile.avatar,
      });
    }    
  },
};
</script>

<style lang="scss">
@import "./scss/styles.scss";
html,
body,
#app,
.container {
  height: 100%;
}
.container {
  @include background-color-container();
}
.content {
  min-height: 0;
}
.el-header {
  @include background-color-content();
}
.el-aside {
  @include background-color-content();
}
.el-main {
  overflow-y: auto;
}
.sf-pagination {
  margin-top: $padding-l;
  @include flex-center();
  .total-page,
  .el-pagination__total {
    @include text-regular($color-text-regular);
  }
  .el-input--mini .el-input__inner {
    height: 32px;
    line-height: 32px;
    border-color: $color-border-primary;
    border-radius: $border-radius-m;
    &:hover {
      border-color: $color-border-primary;
    }
  }
  &.is-background {
    .btn-prev,
    .btn-next,
    .el-pager li {
      border-radius: $border-radius-m;
      background-color: $color-white;
      border: 1px solid $color-border-primary;
      color: $color-text-regular;
      &:not(.disabled):not(:disabled):not(.active):hover {
        color: $color-primary;
        border-color: $color-primary;
      }
      &:not(.disabled).active {
        border-color: $color-primary;
      }
      &:disabled,
      &.disabled {
        background-color: $color-pagination-disabled-background;
      }
    }
  }
}
.sf-loading {
  .el-loading-spinner i {
    font-size: 40px;
  }
}
</style>
