export const departments = [
  {
    code: 'HR',
    id: 'HR',
    name: '人力资源部',
    effectiveDate: '2021-07-02',
    children: [{
      code: 'HR-REC',
      id: 'HR-REC',
      name: '招聘部',
      effectiveDate: '2021-07-02',
    }]
  },
  {
    code: 'MARKET',
    id: 'MARKET',
    name: '市场部',
    effectiveDate: '2021-07-02',
    children: [{
      code: 'MARKET-DEV',
      id: 'MARKET-DEV',
      name: '市场开发部',
      effectiveDate: '2021-07-02',
    }, {
      code: 'MARKET-MARKET',
      id: 'MARKET-MARKET',
      name: '市场营销部',
      effectiveDate: '2021-07-02',
    },{
      code: 'MARKET-INVES',
      id: 'MARKET-INVES',
      name: '市场调研',
      effectiveDate: '2021-07-02',
    }, {
      code: 'MARKET-PLAN',
      id: 'MARKET-PLAN',
      name: '市场策划',
      effectiveDate: '2021-07-02',
    }],
  },
  {
    code: 'TECH',
    id: 'TECH',
    name: '技术部',
    effectiveDate: '2021-07-02',
    children: [{
      code: 'TECH-FE',
      id: 'TECH-FE',
      name: '前端开发部',
      effectiveDate: '2021-07-02',
      children: [{
        code: 'TECH-FE-WEB',
        id: 'TECH-FE-WEB',
        name: 'WEB开发部',
        effectiveDate: '2021-07-02',
      }, {
        code: 'TECH-FE-APP',
        id: 'TECH-FE-APP',
        name: 'APP开发部',
        effectiveDate: '2021-07-02',
      }]
    }, {
      code: 'TECH-BE',
      id: 'TECH-BE',
      name: '后端开发部',
      effectiveDate: '2021-07-02',
      children: [{
        code: 'TECH-BE-JAVA',
        id: 'TECH-BE-JAVA',
        name: 'JAVA开发部',
        effectiveDate: '2021-07-02',
      }, {
        code: 'TECH-BE-PY',
        id: 'TECH-BE-PY',
        name: 'Python开发部',
        effectiveDate: '2021-07-02',
      }, {
        code: 'TECH-BE-C#',
        id: 'TECH-BE-C',
        name: 'C#开发部',
        effectiveDate: '2021-07-02',
      }]
    }]
  },
  {
    code: 'LOGISTIC',
    id: 'LOGISTIC',
    name: '后勤部',
    effectiveDate: '2021-07-02',
    children: []
  },
];

export const employees = [
  {
    id: '100001',
    username: 'zhangsan',
    name: '李四',
    title: '主管',
    departmentCode: 'A-A',
    departmentTitle: '招聘部',
    effectiveDate: '2021-07-02',
  },
  {
    id: '100002',
    username: 'zhangsan',
    name: '张三',
    title: '主管',
    departmentCode: 'A-A',
    departmentTitle: '招聘部',
    effectiveDate: '2021-07-02',
  },
  {
    id: '100003',
    username: 'zhangsan',
    name: '张三',
    title: '主管',
    departmentCode: 'A-A',
    departmentTitle: '招聘部',
    effectiveDate: '2021-07-02',
  },
]