const Department = () => import('./department');
const Employee = () => import('./employee');

const routes = [
  {
    path: '',
    component: Department,
  },
  {
    path: 'employees',
    component: Employee,
  },
];

export default routes;