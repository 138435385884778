import { createRouter, createWebHistory} from 'vue-router';
import RouterContainer from '@components/RouterContainer';
import activeUserRoutes from '@modules/activeUser/routes';
import reportRoutes from '@modules/reports/routes';
import organizationRoutes from '@modules/organizations/routes';

const Home = () => import('../modules/home/index.vue');
const Login = () => import('../modules/login/index.vue');
const Alarm = () => import('../modules/alarm/index.vue');
const Software = () => import('../modules/software/index.vue');
const NotFound = () => import('../modules/error/404.vue');

const routes = [
  { path: '/', name: 'Home', component: Home },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      hideHeader: true, // 是否隐藏header
      hideAside: true, // 是否隐藏左侧导航
    }
  },
  {
    path: '/alarm',
    name: 'Alarm',
    component: Alarm
  },
  {
    path: '/active-user',
    name: 'activeUserRoutes',
    component: RouterContainer,
    children: activeUserRoutes,
  },
  {
    path: '/reports',
    name: 'reportRoutes',
    component: RouterContainer,
    children: reportRoutes,
  },
  {
    path: '/organizations',
    name: 'organizationRoutes',
    component: RouterContainer,
    children: organizationRoutes,
  },
  { path: '/software-usage', name: 'softwareUsage', component: Software },
  { path: '/404', name: '404', component: NotFound },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router;