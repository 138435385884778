import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import 'element-plus/lib/theme-chalk/index.css'
import './scss/element-variable.scss'

import App from './App.vue'
import router from './routes'
import store from './stores'

import Card from './components/Card';
import httpRequest from '@utils/httpRequest';

const app = createApp(App)

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.state.auth.authenticated;
  if ((to.name !== 'Login') && !isAuthenticated) {
    next({ name: 'Login' })
  } else {
    next()
  }
})

app.use(ElementPlus, { locale })
app.use(router)
app.use(store)

app.component('sf-card', Card);

app.config.globalProperties.$httpRequest = httpRequest

window.vm = app.mount('#app')

// TODO: 切换主题
// document.documentElement.setAttribute("data-theme","dark")

