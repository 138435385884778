const Overview = () => import('./overview');
const Details = () => import('./details');

const routes = [
  {
    path: '',
    component: Overview,
  },
  {
    path: 'details',
    component: Details,
  },
];

export default routes;