/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import axios from 'axios';
import { ElMessage } from 'element-plus'

import {
  SESSION_STORAGE_KEYS,
} from '@constants/storage';

const instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_BASEURL,
  timeout: 30000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// 添加请求拦截器
instance.interceptors.request.use(
  (axiosConfig) => {
    const tokenName = sessionStorage.getItem(SESSION_STORAGE_KEYS.tokenName);
    const token = sessionStorage.getItem(SESSION_STORAGE_KEYS.token);
    const tenantId = sessionStorage.getItem(SESSION_STORAGE_KEYS.tenantId);
    if (tokenName && token) {
      axiosConfig.headers[tokenName] = token;
      if(tenantId!=null && tenantId !='undefined'){
        axiosConfig.headers['tenantId'] = tenantId;
      }
    }
    return axiosConfig;
  },
  // 对请求错误做些什么
  error => Promise.reject(error),
);

// 添加响应拦截器
instance.interceptors.response.use(
  // 对响应数据做点什么
  (response) => {
    // TODO: logout when token is invalid
    if (response.data.code === 200) {
      if (response.data.code === 401 && window.location.pathname !== '/login') {
        window.vm.$store.dispatch('auth/logout');
        window.vm.$router.push('/login');
      }
      return response.data;
    }
    const errorMsg = response.data.msg || '请求异常';
    ElMessage.error(errorMsg);
    return Promise.reject(errorMsg);
  },
  // 对响应错误做点什么
  (error) => {
    const errorMsg = `网络异常${error.response ? `${`[${error.response.status}]`}` : ''}`;
    ElMessage.error(errorMsg);
    return Promise.reject(error);
  },
);

export default instance;
