const Organizations = () => import('./organizations');
const Users = () => import('./users');

const routes = [
  {
    path: 'organizations',
    component: Organizations,
  },
  {
    path: 'users',
    component: Users,
  },
];

export default routes;