<template>
  <el-menu
    :default-active="active"
    class="sf-menu"
    router
  >
    <div
      v-for="item in menu"
      :key="item.id"
    >
      <el-submenu
        :index="item.path"
        v-if="item.submenu"
        class="menu-item"
      >
        <template #title>
          <i :class="`iconfont icon-${item.icon}`"></i>
          <span class="menu-item-title">{{item.title}}</span>
        </template>
        <el-menu-item
          v-for="submenu in item.submenu"
          :key="submenu.path"
          :index="submenu.path"
        >
          {{submenu.title}}
        </el-menu-item>
      </el-submenu>
      <el-menu-item
        :index="item.path"
        v-else
        class="menu-item"
      >
        <template #title>
          <i :class="`iconfont icon-${item.icon}`"></i>
          <span class="menu-item-title">{{item.title}}</span>
        </template>
      </el-menu-item>
    </div>
  </el-menu>
</template>

<script>
export default {
  data() {
    return {
      menu: [
        {
          id: 'home',
          path: '/',
          icon: 'timer',
          title: '首页',
        },
        {
          id: 'analysis',
          path: '/analysis',
          icon: 'analysis',
          title: '洞察分析',
          submenu: [
            {
              id: 'active-user',
              path: '/active-user',
              title: '效率一览',
            },
            {
              id: 'software-usage',
              path: '/software-usage',
              title: '软件使用',
            }
          ],
        },
        {
          id: 'reports',
          path: '/reports',
          icon: 'report',
          title: '实时报告',
          submenu: [
            {
              id: 'users',
              path: '/reports/users',
              title: '实时效率-用户',
            },
            {
              id: 'organizations',
              path: '/reports/organizations',
              title: '实时效率-组织',
            }
          ],
        },
        {
          id: 'organizations',
          path: '/organizations',
          icon: 'group-filled',
          title: '组织和用户',
          submenu: [
            {
              id: 'departments',
              path: '/organizations',
              title: '组织维护',
            },
            {
              id: 'organizations',
              path: '/organizations/employees',
              title: '人员维护',
            }
          ],
        },
      ],
    }
  },
  computed: {
    active() {
      return this.$route.path;
    }
  }
}
</script>

<style lang="scss">
.sf-menu {
  border-right: none;
  background-color: transparent;
  .el-submenu .el-menu-item {
    padding: 0 $padding-s 0 $padding-xl !important;
    margin-left: -$padding-s;
    @include background-color-content();
    @include color-primary();
    &.is-active, &:hover {
      color: $color-primary;
    }
  }
  .menu-item {
    &, .el-submenu__title {
      height: 42px;
      line-height: 42px;
      padding: 0 $padding-s !important;
      @include background-color-content();
    }
    .iconfont {
      @include color-secondary();
    }
    .el-submenu__title {
      margin-left: -$padding-s;
      margin-right: -$padding-s;
      .el-submenu__icon-arrow {
        right: $padding-s;
        @include color-secondary();
      }
    }
    .menu-item-title {
      margin-left: $padding-s;
      @include text-regular($color-text-primary);
      @include color-primary();
    }
    &:not(.is-opened):not(.is-active):hover {
      .menu-item-title, .iconfont, .el-submenu__icon-arrow {
        color: $color-primary;
      }
    }
    &.is-active {
      &:not(.is-opened), .el-submenu__title {
        background-color: $color-primary;
        border-radius: 4px;
        i {
          color: $color-white;
        }
      }
      .iconfont {
        color: $color-white;
      }
      .menu-item-title {
        color: $color-white;
      }
    }
    &.is-opened {
      height: auto;
    }
  }
}
</style>